import _find from 'lodash/find';

/**
 * Given a product view, resolves the correct product look and sku based on the default IDs set on the view.
 * @param {*} product the product view
 */
export function resolveProductLookAndSku(product) {
  const { productLooks, defaultProductLookId, defaultSkuId } = product;
  const defaultProductLook =
    _find(productLooks, (productLook) => productLook.id === defaultProductLookId) ||
    productLooks[0];
  const { skus } = defaultProductLook;
  const defaultSku = _find(skus, (sku) => sku.id === defaultSkuId) || skus[0];
  return {
    productLook: defaultProductLook,
    sku: defaultSku,
  };
}

/**
 * Determines whether a product's default SKU is addable to registry.
 * @param {*} product the product view
 */
export function canAddToRegistry(product) {
  const { sku } = resolveProductLookAndSku(product);
  return sku.canAddToRegistry;
}

/**
 * Returns a product image of a selectd size
 * @param {*} productLook the product look
 * @param {string} imageSize the product image size
 * @param {boolean} isRectangularImage - if true look up rectangular image
 */
export function getProductImage(productLook, imageSize, isRectangularImage) {
  const squareImage =
    productLook &&
    productLook.images &&
    productLook.images[0] &&
    productLook.images[0].aspect_ratios['1x1'] &&
    productLook.images[0].aspect_ratios['1x1'][imageSize];
  let rectangularImage;
  if (isRectangularImage) {
    const aspectRatios =
      productLook &&
      productLook.images &&
      productLook.images[0] &&
      productLook.images[0].aspect_ratios;
    const rectangularRatio = aspectRatios && (aspectRatios['3x4'] || aspectRatios['2x3']); // check for 3x4 ratio first, then fall back to deprecated 2x3 ratio
    rectangularImage = (rectangularRatio && rectangularRatio[imageSize]) || squareImage; // use square image if we don't have a true rectangular one
  }
  const image = isRectangularImage ? rectangularImage : squareImage;
  return image;
}

/**
 * Gets the category for a product, if available.
 * Returns null if none is available.
 * @param {*} product the product
 */
export function getProductCategory(product) {
  const categoryChain = product.category_chain;
  if (categoryChain && categoryChain.length) {
    return categoryChain[categoryChain.length - 1];
  }

  return null;
}
