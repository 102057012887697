export const REQUEST_QUESTIONS = 'zola/onboard/REQUEST_QUESTIONS';
export const RECEIVE_QUESTIONS = 'zola/onboard/RECEIVE_QUESTIONS';
export const UPDATE_QUESTION_NUMBER = 'zola/onboard/UPDATE_QUESTION_NUMBER';
export const UPDATE_GIFTS_ADDED_COUNT = 'zola/onboard/UPDATE_GIFTS_ADDED_COUNT';
export const REQUEST_SIGNUP = 'zola/onboard/REQUEST_SIGNUP';
export const ONBOARDING_COMPLETED = 'zola/onboard/ONBOARDING_COMPLETED';
export const UPDATE_WEDDING_DATE = 'zola/onboard/UPDATE_WEDDING_DATE';

export const SET_ADD_TO_REGISTRY_PRODUCT_DATA = 'zola/onboard/SET_ADD_TO_REGISTRY_PRODUCT_DATA';
export const SET_ADD_TO_REGISTRY_EVENT_DATA = 'zola/onboard/SET_ADD_TO_REGISTRY_EVENT_DATA';
export const SET_ONBOARD_REDIRECT = 'zola/onboard/SET_ONBOARD_REDIRECT';
export const CLEAR_ONBOARD_ADD_REGISTRY_DATA = 'zola/onboard/CLEAR_ONBOARD_ADD_REGISTRY_DATA';
export const UPDATE_SUBMIT_DELAY_ACTIVE = 'zola/onboard/UPDATE_SUBMIT_DELAY_ACTIVE';
