import _sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import { canAddToRegistry } from '../util/productHelper';
import * as ActionTypes from '../actions/types/OnboardActionTypes';

export const initialState = {
  questions: [],
  isSigningUp: false,
  hasSignedUp: false,
  questionsLoaded: false,
  currentQuestionNumber: 0,
  giftsAdded: 0,
  redirectTo: '',
  isRegistryRedirect: false,
  productData: {},
  eventData: {},
  submitDelayActive: false,
};

const onboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_QUESTIONS: {
      // sort questions by their position
      const questions = _sortBy(action.payload.data.questions, ['position']);
      return Object.assign({}, state, {
        ...action.payload.data,
        questions,
        questionsLoaded: true,
      });
    }
    case ActionTypes.UPDATE_GIFTS_ADDED_COUNT: {
      const giftsAdded = action.payload;
      return { ...state, giftsAdded };
    }
    case ActionTypes.UPDATE_QUESTION_NUMBER: {
      const questionNumber = action.payload;
      return { ...state, questionNumber };
    }
    case ActionTypes.REQUEST_QUESTIONS: {
      return { ...state, questionsLoaded: false };
    }
    case ActionTypes.REQUEST_SIGNUP: {
      return { ...state, isSigningUp: true };
    }
    case ActionTypes.ONBOARDING_COMPLETED: {
      return { ...state, isSigningUp: false, hasSignedUp: true, ...action.payload.data };
    }
    case ActionTypes.UPDATE_WEDDING_DATE: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.SET_ADD_TO_REGISTRY_EVENT_DATA:
      return { ...state, eventData: action.eventData };
    case ActionTypes.SET_ADD_TO_REGISTRY_PRODUCT_DATA:
      return { ...state, productData: action.productData };
    case ActionTypes.SET_ONBOARD_REDIRECT:
      return { ...state, redirectTo: action.path, isRegistryRedirect: action.isRegistry };
    case ActionTypes.CLEAR_ONBOARD_ADD_REGISTRY_DATA:
      return {
        ...state,
        redirectTo: '',
        isRegistryRedirect: false,
        productData: {},
        eventData: {},
      };
    case ActionTypes.UPDATE_SUBMIT_DELAY_ACTIVE: {
      return Object.assign({}, state, { submitDelayActive: action.payload });
    }
    default:
      return state;
  }
};

export default onboardReducer;

// Extracts all products that are part of the "add products" interstitial collection
const getAllInterstitialProducts = (state) => state.registryCollection.products;

// Memoized selector that filters the interstitial products down to those that can be successfully added to registry
export const getAddableInterstitialProducts = createSelector(
  getAllInterstitialProducts,
  (allProducts) => allProducts.filter(canAddToRegistry)
);
