import * as ActionTypes from '../actions/types/BlenderActionTypes';

const initialState = {
  products: [],
  maxVisibleCards: 3,
  activeCardIdx: 0,
  startingCardIdx: 0,
  hasViewedAllProducts: false,
};

const blenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REGISTRY_COLLECTION: {
      return { ...state, products: action.payload };
    }
    case ActionTypes.CARD_PASSED_OR_ADDED: {
      const { products, activeCardIdx } = state;
      // only allowed if there is a product
      if (products[activeCardIdx]) {
        products[activeCardIdx] = { ...products[activeCardIdx], ...action.payload };
        return { ...state, products, activeCardIdx: activeCardIdx + 1 };
      }
      return state;
    }
    case ActionTypes.REMOVE_TOP_CARD: {
      const { startingCardIdx, products, hasViewedAllProducts } = state;
      // if they have already gotten to the bottom, do nothing
      if (hasViewedAllProducts) {
        return state;
      }
      // if there is a product at the next starting index, simply increment
      if (products[startingCardIdx + 1]) {
        return { ...state, startingCardIdx: startingCardIdx + 1 };
      }
      // otherwise, this is the last card. remove it and update the state to hasViewedAll
      return { ...state, startingCardIdx: startingCardIdx + 1, hasViewedAllProducts: true };
    }
    case ActionTypes.RESET_BLENDER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default blenderReducer;
