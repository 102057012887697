import * as ActionTypes from '../actions/types/FileUploadActionTypes';

const initialState = {
  filePreview: {},
};

const cashFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILE_TEMP_UPLOADED: {
      const filePreview = Object.assign(state.filePreview, action.payload);
      return Object.assign({}, state, { filePreview });
    }
    case ActionTypes.FILE_CROPPED: {
      const filePreview = Object.assign(state.filePreview, action.payload);
      return Object.assign({}, state, { filePreview });
    }
    default:
      return state;
  }
};

export default cashFundReducer;
