import { Reducer } from 'redux';
import * as ActionTypes from '../actions/types/CartActionTypes';

type CheckoutDataCodes = { code: string; code2?: string; fullName: string }[];

type CartState = {
  busy: boolean;
  cartId?: string;
  cartMessage?: string | null;
  checkoutData?: {
    cartId?: string;
    countryCodes?: CheckoutDataCodes;
    provinceCodes?: CheckoutDataCodes;
    stateCodes?: CheckoutDataCodes;
  };
  details?: {
    availableCredit?: number;
    canShip?: boolean;
    cashPaymentRequired?: boolean;
    checksum?: string;
    containsChangeTheDate?: boolean;
    expediteDisabled?: boolean;
    expediteHidden?: boolean;
    isOwnRegistry?: boolean;
    isRegistry?: boolean;
    isUsingCredits?: boolean;
    isWeddingDate?: boolean;
    maxApplicableCredit?: number;
    needEmail?: boolean;
    ownRegistry?: boolean;
    registrantNames?: string;
    registry?: boolean;
    registryId?: string;
    shipToName?: string;
    shippingProcessingToCost?: { code: string; cost: number; label: string }[];
    usingCredits?: boolean;
    weddingDate?: string | Date;
  };
  freeShipping?: { amountLeft: number; metThreshold: boolean } | null;
  initialized: boolean;
  isFreeShippingEligible?: boolean;
  items: any[]; // TODO: Define items
  promo?: string;
  size: number;
  totals: {
    subtotal?: number;
    tax?: number;
    deliverySurcharge?: number;
    supplyChainFee?: number;
    shipping?: number;
    creditApplied?: number;
    discountsApplied?: number;
    handlingFee?: number;
    total?: number;
    amountSaved?: number;
  };
  validateShipping?: boolean;
};

const initialState = {
  details: {},
  busy: false,
  initialized: false,
  size: 0, // sometimes we will only have this
  items: [],
  totals: {},
  isFreeShippingEligible: false,
  validateShipping: true,
  freeShipping: null, // temporary
};

const cartReducer: Reducer<CartState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_SIZE: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_SIZE: {
      return Object.assign({}, state, {
        busy: false,
        size: action.payload,
      });
    }
    case ActionTypes.REQUEST_CART: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_CART: {
      return Object.assign({}, state, {
        busy: false,
        initialized: true,
        cartMessage: action.payload.cartMessage,
        size: action.payload.items.length,
        items: action.payload.items,
        totals: action.payload.totals,
        isFreeShippingEligible: action.payload.is_free_shipping_eligible,
        freeShipping: action.payload.freeShipping,
      });
    }
    case ActionTypes.REQUEST_UPDATED_CART: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_UPDATED_CART: {
      return Object.assign({}, state, {
        cartMessage: action.payload.cartMessage,
        details: action.payload.details,
        items: action.payload.items,
        promo: action.payload.promo,
        totals: action.payload.totals,
      });
    }
    case ActionTypes.ADD_ITEM: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.ADDED_ITEM: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.ADD_ITEM_TO_CART: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.ADDED_ITEM_TO_CART: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.UPDATE_ITEM_QUANTITY: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.DELETE_ITEM: {
      return Object.assign({}, state, {});
    }
    case ActionTypes.RECEIVE_DELETED_ITEMS: {
      return Object.assign({}, state, { deletedCartItems: action.payload });
    }
    case ActionTypes.RECEIVE_INITIAL_CART_DETAILS: {
      return Object.assign({}, state, {
        cartMessage: action.payload.cart.cartMessage,
        details: action.payload.cart.details,
        promo: action.payload.cart.promo,
        totals: action.payload.cart.totals,
        checkoutData: action.payload.checkoutData,
        cartId: action.payload.checkoutData && action.payload.checkoutData.cartId,
      });
    }
    case ActionTypes.RECEIVE_CREDIT_CARD: {
      return Object.assign({}, state, {
        card: action.payload,
      });
    }
    case ActionTypes.RECEIVE_REMOVE_CREDIT_CARD: {
      return Object.assign({}, state, {});
    }

    case ActionTypes.RESET_CART: {
      return initialState;
    }
    case ActionTypes.UNLOAD_CART: {
      return Object.assign({}, initialState, {
        size: state.size,
      });
    }
    case ActionTypes.RECEIVE_SHIPPING_VALIDATION: {
      const validateShipping =
        action.payload &&
        !action.payload.allowBypass &&
        (!action.payload.data || !action.payload.data.isValidating);
      const shippingError = action.payload.data && action.payload.data.userMessage;
      return {
        ...state,
        shippingError,
        validateShipping,
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
