import * as ActionTypes from '../actions/types/CashFundActionTypes';

const initialState = {
  categoriesLoaded: false,
  templatesLoaded: false,
  showTemplatesMobile: false,
  categories: [],
  templatesByCategory: {},
  fundImages: [],
  filePreview: {},
  previouslyAddedFund: '',
};

const cashFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_CATEGORIES: {
      return { ...state, categoriesLoaded: true, categories: action.payload };
    }
    case ActionTypes.RECEIVE_TEMPLATES: {
      return { ...state, templatesLoaded: true, templatesByCategory: action.payload };
    }
    case ActionTypes.RECEIVE_FUND_IMAGES: {
      return { ...state, fundImages: action.payload };
    }
    case ActionTypes.RESET_ADDED_FUND: {
      return { ...state, previouslyAddedFund: '' };
    }
    case ActionTypes.FUND_ADDED: {
      return { ...state, previouslyAddedFund: action.payload };
    }
    case ActionTypes.TOGGLE_DISPLAY_MOBILE_TEMPLATES: {
      return { ...state, showTemplatesMobile: !state.showTemplatesMobile };
    }
    default:
      return state;
  }
};

export default cashFundReducer;
