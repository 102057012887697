import type { WRegistryCollectionItemViewResponse } from '@zola/svc-web-api-ts-client';

export const REQUEST_ADD_TO_REGISTRY = 'zola/registryCollection/REQUEST_ADD_TO_REGISTRY';
export const RECEIVE_ADD_TO_REGISTRY = 'zola/registryCollection/RECEIVE_ADD_TO_REGISTRY';
export const REQUEST_REGISTRY_COLLECTION = 'zola/registryCollection/REQUEST_REGISTRY_COLLECTION';
export const RECEIVE_REGISTRY_COLLECTION = 'zola/registryCollection/RECEIVE_REGISTRY_COLLECTION';
export const REQUEST_REMOVE_FROM_REGISTRY = 'zola/registryCollection/REQUEST_REMOVE_FROM_REGISTRY';
export const RECEIVE_REMOVE_FROM_REGISTRY = 'zola/registryCollection/RECEIVE_REMOVE_FROM_REGISTRY';
export const UPDATE_PRODUCT = 'zola/registryCollection/UPDATE_PRODUCT';
export const REQUEST_SEARCH = 'zola/registryCollection/REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'zola/registryCollection/RECEIVE_SEARCH';
export const SELECT_COLLECTION = 'zola/registryCollection/SELECT_COLLECTION';
export const TOGGLE_FACET_VALUE = 'zola/registryCollection/TOGGLE_FACET_VALUE';
export const RESET_ALL_FACET_VALUE = 'zola/registryCollection/RESET_ALL_FACET_VALUE';
export const RESET_FACET_VALUE = 'zola/registryCollection/RESET_FACET_VALUE';

export type ReceiveAddToRegistryType = {
  type: typeof RECEIVE_ADD_TO_REGISTRY;
  payload: WRegistryCollectionItemViewResponse;
};
